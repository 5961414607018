<template>
  <div class="tw-rounded-full tw-bg-white tw-p-1">
    <svg class="tw-h-4 tw-w-4 tw-text-black" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <use :href="getCategoryImage(category.mdi)+'#' + category.mdi"/>
    </svg>

  </div>
</template>

<script>
export default {
  props: ['category',],
  methods: {
    getCategoryImage(iconName) {
      try {
        return require('@/assets/img/categories/' + iconName + '.svg');
      } catch (e) {
        return null;
      }
    }
  }

}
</script>