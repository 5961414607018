<template>
  <div>
    <form @submit.prevent="onSubmit">
      <div class="tw-bg-white tw-space-y-4 tw-p-4 tw-leading-6">
        <h3 class="tw-text-xl tw-font-semibold tw-leading-8">
          Send a message
        </h3>
        <div class="tw-space-y-1 tw-mt-4">
          <div v-show="error" class="alert alert-info" role="alert">
            {{ error }}
          </div>
        </div>
        <div class="tw-space-y-1">

          <ml-label required for="message">Content</ml-label>
          <textarea id="message" rows="5" v-model="message" required
                            class="tw-border tw-border-solid tw-p-4 tw-border-gray-n1 tw-rounded-xl tw-w-full tw-p-0
                            tw-text-sm tw-text-gray-n4 tw-placeholder-gray-n3 tw-leading-relaxed"></textarea>
          <ml-error-msg :msg="errors['message']" />

        </div>
        <div v-if="!user">
          <div class="tw-mt-2 tw-space-y-1">
            <ml-label required for="name">Name</ml-label>
            <div class="tw-mt-2">
              <ml-input-text @change="validateForm" v-model="name" id="name" name="name" required/>
              <ml-error-msg :msg="errors['name']" />
            </div>
          </div>
          <div class="tw-mt-2 tw-space-y-1">
            <ml-label required for="email">Email</ml-label>
            <div class="tw-mt-2">
              <ml-input-text @change="validateForm" v-model="email" id="email" name="email" required/>
              <ml-error-msg :msg="errors['email']" />
            </div>
          </div>
          <div class="tw-mt-6 tw-flex tw-items-left tw-space-x-3">
            <input v-model="terms_and_conditions" id="terms_and_conditions" type="checkbox" value="1"
                   class="w-4 h-4 tw-text-red tw-bg-gray-n tw-ring-1 tw-ring-black rounded tw-border-black focus:tw-ring-red
                    dark:focus:tw-ring-red-100 dark:tw-ring-offset-gray-n1 focus:tw-ring-2 dark:tw-bg-gray-n1 dark:tw-border-gray-n2">

            <label for="terms_and_conditions" class="tw-text-xs tw-leading-relaxed tw-text-black">
              By submitting this form, I agree to be bound by the <router-link target="_blank" class="tw-text-red" :to="{ name: 'terms.and.conditions'}">{{ $t('footer.terms_and_conditions') }}</router-link>
              and <router-link target="_blank" class="tw-text-red" :to="{ name: 'privacy.policy'}">{{ $t('footer.privacy_policy') }}</router-link>.
            </label>

          </div>
          <ml-error-msg :msg="errors['terms_and_conditions']" />
        </div>
        <div class="tw-mt-10 tw-grid tw-grid-cols-2 tw-gap-x-4">
                <span @click="clearMessage"
                      class="tw-cursor-pointer tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-border tw-border-solid tw-border-black tw-text-base tw-font-medium tw-rounded-xl tw-text-black tw-bg-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
                  Cancel
                </span>
          <button :disabled="!message"
                  class="disabled:tw-opacity-50 tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-text-white tw-bg-gray-n4 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
            Send
          </button>
        </div>

      </div>
    </form>
  </div>
</template>


<script>

import { mapGetters } from 'vuex'
import MlLabel from "@/components/layout/form/MlLabel";
import MlInputText from "@/components/layout/form/MlInputText";
import MlErrorMsg from "@/components/layout/form/MlErrorMsg";
export default {
  middleware: ['guest'],

  components: {
    MlLabel, MlInputText, MlErrorMsg
  },
  props: ['vendor', 'chat'],
  data() {
    return {
      message: null,
      email: null,
      name: null,
      terms_and_conditions: null,
      valid: true,
      lazy: true,
      errors: [],
      error: null
    }
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      authenticated: 'auth/authenticated',
    }),
  },
  watch: {},
  methods: {

    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validateForm() {

      console.log("validate form...");
      this.errors = [];
      let label = '';

      if (!this.message) {
        label = "Message required.";
        this.errors.push(label);
        this.errors["message"] = label;
      }

      if (!this.user) {
        if (!this.name) {
          label = "Name Required";
          this.errors.push(label);
          this.errors["name"] = label;
        }
        if (!this.terms_and_conditions) {
          label = "Terms and Conditions Required";
          this.errors.push(label);
          this.errors["terms_and_conditions"] = label;
        }

        if (!this.email) {
          label = "Email Required";
          this.errors.push(label);
          this.errors["email"] = label;
        } else if (!this.validEmail(this.email)) {
          label = "Valid Email Required";
          this.errors.push(label);
          this.errors["email"] = label;
        }
      }


      if (!this.errors.length) {
        this.valid = true;
        return true;
      }

      this.valid = false;
      return false;

    },
    onSubmit() {
      if (this.validateForm()) this.sendMessage();
    },
    sendMessage() {

      let messageObj = {
        to_user_id: this.vendor.id,
        vendor_id: this.vendor.id,
        message: this.message,
        email: this.email,
        name: this.name,
        terms_and_conditions: this.terms_and_conditions
      }

      this.$http.post('/api/messages', messageObj)
          .then((response) => {
            this.$toast.success(response.data.message, "Success");
            this.clearMessage();
          }).catch((error) => {
            if (error.request) {
              this.error = error.data.message;
              this.$toast.info(error.data.message, "info");
            }
          });
    },
    clearMessage() {
      this.message = null;
      this.name = null;
      this.email = null;
      this.terms_and_conditions = null;
      this.error = null;
      this.$emit('close');
    },
  }
}
</script>