<template>
  <div class="tw-max-w-9xl tw-mx-auto sm:tw-px-8 lg:tw-px-16 tw-px-3">
    <div>
      <div>
        <spinner v-if="loading"></spinner>

        <div v-if="errored && errorMsg.status == '404'">

          <div class="alert alert-warning" role="alert">
            {{ errorMsg.statusText }}
          </div>

        </div>
        <!-- Showcase gallery -->
        <div v-if="vendor.showcasework && !loading" class="tw-text-left tw-grid tw-grid-cols-8 tw-gap-x-8">

          <!-- Showcase title and gallery -->
          <div class="tw-text-sm mb-3 tw-col-span-8">
            <span class="tw-text-red">{{$t('vendor.showcasework.wedding_offers')}}</span> /
            {{ vendor.showcasework.title }}
          </div>
          <div v-if="images" class="tw-col-span-8">
            <gallery :images="images" />
          </div>
        </div>
        <div v-else-if="!loading" class="tw-text-left tw-grid tw-grid-cols-8 tw-gap-x-8">
          <div class="tw-text-sm mb-3 tw-col-span-8">
            <span class="tw-text-red">{{$t('vendor.showcasework.wedding_offers')}}</span> /
            No Showcase Work created.
          </div>
        </div>

        <!-- Tool bar -->
        <div v-if="!loading" class="tw-col-span-8">
          <div
            class="tw-flex tw-flex-wrap lg:tw-flex-nowrap tw-mb-4 lg:tw-mb-0 tw-justify-between tw-items-start tw-mt-4">
<!--            <div class="tw-flex">-->

              <div class="tw-flex tw-items-center tw-text-sm tw-mb-4 tw-ml-2">
                <img :src="vendor.avatar" class="tw-h-6 tw-mr-2 tw-rounded-full" />
                <div v-if="vendor.id">
                  <span v-if="vendor.id">{{ vendor.companies.name || vendor.name }}</span>
                </div>
              </div>
<!--            </div>-->


            <div>
              <div class="tw-flex tw-items-center tw-space-x-4">

                <div v-if="vendor.showcasework && !loading" class="tw-flex">
                  <div v-if="vendor.showcasework.youtube" href="#" @click="playVideo('youtube')"
                       class="tw-flex-inline tw-cursor-pointer tw-border tw-rounded-xl tw-border-gray-n1 tw-mr-2 tw-px-4 tw-py-2 tw-flex tw-items-center">
                    <span class="tw-text-sm tw-font-semibold tw-leading-relaxed tw-text-black tw-mr-2">YouTube</span>
                    <img src="@/assets/img/youtube.png" class="tw-w-6 tw-w-6" width="6" height="6"
                         alt="Play YouTube Video">
                  </div>
                  <div v-if="vendor.showcasework.vimeo" href="#" @click="playVideo('vimeo')"
                       class="tw-flex-inline tw-cursor-pointer tw-border tw-rounded-xl tw-border-gray-n1 tw-mr-2 tw-px-4 tw-py-2 tw-flex tw-items-center">
                    <span class="tw-text-sm tw-font-semibold tw-leading-relaxed tw-text-black tw-mr-2">Vimeo</span>
                    <img src="@/assets/img/vimeo.png" class="tw-w-6 tw-w-6" width="6" height="6" alt="Play Vimeo Video">
                  </div>
                </div>

                <Button type="s_red" @click="messageDialog = true" class="tw-flex tw-flex-inline tw-h-3 tw-items-center">
                  <message-square-icon size="1.5x" class="custom-class"></message-square-icon>
                  <span>Message vendor</span>
                </Button>
                <div @click="dialogQuote = true"
                     class="tw-flex-inline tw-cursor-pointer tw-border tw-rounded-xl tw-border-red tw-bg-red tw-text-white tw-px-4 tw-py-4">
                  <span class="tw-text-sm tw-font-semibold tw-leading-relaxed">Request a Quote</span>
                </div>
              </div>

              <span class="tw-flex tw-justify-end">

<!--              categories-->
                <span v-if="categories" class="tw-relative">
                  <div
                      class="tw-bottom-3 tw-left-3 tw-flex tw-gap-1 tw-text-xs tw-ml-4 tw-mt-4"
                      @mouseover="showCategories = true"
                      @mouseout="showCategories = false"
                  >
                    <Category v-for="category in displayedCategories" :key="category.id" :category="category" class="tw-border-1 tw-border-gray-n2 tw-shadow-xl"/>
                    <span
                        v-if="categories.length > 3"
                        class="tw-flex tw-rounded-full tw-bg-white tw-h-6 tw-w-6 tw-text-black tw-items-center tw-justify-center tw-border-1 tw-border-gray-n2 tw-shadow-xl"
                    >
                      <p class="tw-m-0">+{{ categories.length - 2 }}</p>
                    </span>

                  </div>

                  <div
                      v-if="showCategories"
                      class="tw-absolute tw-text-sm tw-text-black tw-p-4 tw-bg-white tw-rounded-xl tw-shadow-md tw-mt-2 tw-z-50 tw-flex tw-flex-col tw-gap-2"
                  >
                    <p class="tw-m-0" v-for="category in categories" :key="category.id"> {{ category.name }} </p>
                  </div>
                </span>

                <!--              area of coverage-->
                <span class="tw-relative" >
                  <span v-if="vendor.countries" class="tw-flex tw-items-center tw-text-xs tw-ml-4 tw-mt-4">
                    <MapPinIcon class="tw-text-red tw-h-4"/>
                    <span
                        v-for="(country,index) in displayedCountries" :key="index"
                    >
                      {{country.code}}{{index != displayedCountries.length-1 ? ',&nbsp;' : ''}}
                    </span>
                    <span
                        v-if="vendor.countries.length > 3"
                        class="tw-ml-1"
                        @mouseover="showCountries = true"
                        @mouseout="showCountries = false"
                    >
                      <p class="tw-m-0">+{{ vendor.countries.length - 3 }} more</p>
                    </span>

                  </span>
                  <div
                      v-if="showCountries"
                      class="tw-absolute tw-max-h-72 tw-text-sm tw-text-black tw-p-4 tw-bg-white tw-rounded-xl tw-shadow-md tw-mt-2 tw-z-50 tw-flex tw-flex-wrap tw-gap-2"
                  >
                    <span
                        v-for="(country,index) in vendor.countries" :key="index"
                    >
                      {{country.code}}{{index != vendor.countries.length-1 ? ',&nbsp;' : ''}}
                    </span>
                  </div>
                </span>
              </span>
            </div>
          </div>
        </div>

        <!-- Showcase desc -->
        <div v-if="vendor.showcasework && !loading" class="tw-text-sm tw-col-span-8 lg:tw-col-span-6">
          <h1 class="tw-font-semibold tw-text-xl tw-leading-relaxed tw-mb-2">{{ vendor.showcasework.title }}</h1>
          <h5 class="tw-font-semibold tw-mb-3">Description</h5>
          <p class="tw-leading-relaxed" v-html="vendor.showcasework.description">
          </p>
        </div>

         <!-- Offers -->
        <div class="tw-grid tw-grid-cols-8">

          <!-- Offers -->
          <div v-if="products && !loading" class="tw-text-sm tw-col-span-8 lg:tw-col-span-6">

            <div class="tw-border-b tw-border-gray-n0 tw-flex tw-justify-between tw-items-end">
              <nav class="tw--mb-px tw-flex tw-space-x-8 tw-leading-relaxed" aria-label="Tabs">
                <a @click.prevent="productTab = 'offers'" href="#"
                  :class="[productTab === 'offers' ? 'tw-border-black tw-text-black' : 'tw-border-transparent tw-text-gray-n2 hover:tw-text-gray-n3 hover:tw-border-gray-n3', 'tw-no-underline tw-group tw-inline-flex tw-items-center tw-py-3 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm']">
                  <span>Available offers <span v-show="products.length">({{ products.length }})</span></span>
                </a>
                <a @click.prevent="productTab = 'quotes'"
                  :class="[productTab === 'quotes' ? 'tw-border-black tw-text-black' : 'tw-border-transparent tw-text-gray-n2 hover:tw-text-gray-n3 hover:tw-border-gray-n3', 'tw-no-underline tw-group tw-inline-flex tw-items-center tw-py-3 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm']"
                  href="#">
                  <span>Quotes requests <span v-show="quotes.length">({{ quotes.length }})</span></span>
                </a>
              </nav>

            </div>
            <div class="tw-flex tw-font-semibold tw-mb-3 tw-pb-3 tw-border-bottom tw-border-gray-n0 tw-leading-relaxed">
              <h5></h5>
              <h5></h5>
            </div>
            <div v-if="products.length > 0" v-show="productTab === 'offers'">
              <div class="tw-space-y-2 tw-mt-1">
                <!-- Dates selection -->
                <v-dialog ref="dialog" v-model="datesSeleted" :return-value.sync="dates" persistent width="320px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-combobox class="text-caption remove-bg-fix" dense v-model="dates" multiple chips deletable-chips
                      small-chips v-bind="attrs" v-on="on" clearable @focus="datesSeleted=true"
                      @click:append="datesSeleted=true">
                      <template v-slot:selection="{ attrs, item, parent, selected }">
                        <v-chip v-bind="attrs" :color="`${item.color} lighten-3`" :input-value="selected" label small>
                          <span class="pr-2">
                            {{ dateFormat(item) }}
                          </span>
                          <v-icon small @click="parent.selectItem(item)">
                            $delete
                          </v-icon>
                        </v-chip>
                      </template>

                      <template v-slot:label>
                        <span class="tw-font-semibold tw-text-xl">{{ $t('filter.wedding_date') }}</span>
                      </template>
                      <template v-slot:append>
                        <svg width="26" height="27" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M12.6667 3.16663H3.33333C2.59695 3.16663 2 3.76358 2 4.49996V13.8333C2 14.5697 2.59695 15.1666 3.33333 15.1666H12.6667C13.403 15.1666 14 14.5697 14 13.8333V4.49996C14 3.76358 13.403 3.16663 12.6667 3.16663Z"
                            stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M10.6665 1.83337V4.50004" stroke="#BDBDBD" stroke-linecap="round"
                            stroke-linejoin="round" />
                          <path d="M5.3335 1.83337V4.50004" stroke="#BDBDBD" stroke-linecap="round"
                            stroke-linejoin="round" />
                          <path d="M2 7.16663H14" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </template>
                    </v-combobox>
                  </template>
                  <div class="tw-flex tw-flex-col tw-items-center tw-p-6 tw-bg-white">
                    <p class="tw-text-xl tw-font-semibold tw-mb-4 tw-self-center">Choose dates</p>
                    <v-calendar-io is-expanded class="vc-border-none" :min-date="new Date()" :attributes="attributes"
                      @dayclick="onDayClick" />
                    <Button class="tw-mt-4 tw-w-full" type="p_dark"
                      @click="$refs.dialog.save(dates);datesSeleted = false">Apply
                    </Button>
                  </div>
                </v-dialog>
                <!-- END -->
              </div>
              <div class="tw-space-y-4">

                <VendorShowcaseProduct v-for="(product) in products" :key="product.id" :product=product :vendor=vendor
                  :selectDates="selectDates" :preselectedDates="dates" :ploading="ploading"></VendorShowcaseProduct>
              </div>
            </div>
            <div v-if="quotes.length > 0" v-show="productTab === 'quotes'">
              <div class="tw-space-y-4">
                <VendorShowcaseProduct v-for="(quote) in quotes" :key="quote.id" :product=quote :vendor=vendor
                  :selectDates="quote.quote.dates.length > 0 ? quote.quote.dates[0].datef:null" :preselectedDates="[]"
                  :ploading="ploading"></VendorShowcaseProduct>
              </div>
            </div>
            <spinner v-else-if="!loading && ploading"></spinner>
          </div>
          <div v-else-if="!loading" class="tw-text-left tw-grid tw-grid-cols-8 tw-gap-x-8">
            <div class="tw-text-sm mb-3 tw-col-span-8">
              <span class="tw-text-red">{{$t('vendor.showcasework.wedding_offers')}}</span> /
              No Offer created.
            </div>
          </div>

          <!-- Right side (rating etc) -->
          <div v-if="!loading" class="tw-col-span-8 lg:tw-col-span-2 tw-space-y-4 tw-space-x-4">

            <div class="tw-flex tw-space-x-4 tw-ml-4 tw-mt-2">
              <div v-if="vendor.companies && (vendor.companies.instagram_link || vendor.companies.facebook_link)"
                   class="tw-flex tw-space-x-3 ">
                <span v-if="vendor.companies.facebook_link" @click="goTo(vendor.companies.facebook_link)"
                      class="facebook tw-cursor-pointer tw-h-8 tw-w-8 tw-inline-flex tw-justify-center tw-items-center tw-rounded-full">
                  <facebook-icon class="tw-w-5 tw-h-5" />
                </span>
                <span v-if="vendor.companies.instagram_link" @click="goTo(vendor.companies.instagram_link)"
                      class="instagram tw-cursor-pointer tw-bg-gray-n3 tw-h-8 tw-w-8 tw-inline-flex tw-justify-center tw-items-center tw-rounded-full tw-text-white">
                  <instagram-icon class="tw-w-5 tw-h-5" />
                </span>
              </div>
            </div>

            <div class="tw-flex tw-items-center">
              <div class="tw-flex tw-items-center tw-inline-flex">

                <v-rating :value="vendor.averageRating" color="red" background-color="red" class="tw-flex" dense
                          emptyIcon='mdi-heart-outline' fullIcon='mdi-heart' halfIcon='mdi-heart-half-full' half-increments
                          readonly size="10"></v-rating>

                <span style="font-size: 8px" class="tw-text-red tw-font-semibold tw-ml-1">{{
                    vendor.averageRating
                  }}</span>

              </div>
              <a href="#" @click.prevent="dialogReviews = true"
                 class="tw-text-red tw-ml-auto tw-text-xs tw-font-semibold tw-no-underline">Read more</a>
            </div>

            <div v-for="(rating, index) in ratings.slice(0, 3)" :key="index"
                 class="tw-border tw-border-gray-n1 tw-rounded-lg tw-p-4">
              <div class="tw-flex tw-items-center tw-text-sm tw-mb-4">
                <img :src="rating.author.avatar" class="tw-h-6 tw-w-6 tw-mr-2 tw-rounded-full" />
                {{ rating.author.name }}
                <div class="tw-ml-auto tw-flex tw-items-center">
                  <v-rating :value="rating.rating" color="red" background-color="red" class="tw-flex" dense
                            emptyIcon='mdi-heart-outline' fullIcon='mdi-heart' halfIcon='mdi-heart-half-full' half-increments
                            readonly size="10"></v-rating>
                  <span style="font-size: 8px" class="tw-text-red tw-font-semibold tw-ml-1">{{ rating.rating }}</span>
                </div>
              </div>
              <div class="tw-font-sm tw-leading-relaxed">
                {{ rating.body | truncate(85) }}
              </div>
              <a v-if="rating.body.length>85" href="#" @click.prevent="reviewClicked(rating)"
                 class="tw-text-red tw-block tw-text-right tw-text-xs tw-font-semibold tw-no-underline">Read more</a>
            </div>

            <div @click="dialogReviews = true" class="tw-cursor-pointer">
              <span class="tw-text-sm tw-font-semibold tw-leading-relaxed tw-text-black tw-inline-flex tw-items-center">
                <pen-tool-icon size="1x" class="tw-mr-1" />
                Write a Review
              </span>

            </div>
          </div>


        </div>

      </div>

      <!-- Modal for single reviews -->
      <v-dialog v-model="singleReview" width="500px">
        <div class="tw-bg-white tw-px-10 tw-py-8">
          <div class="tw-flex tw-justify-between tw-items-center tw-mb-4">
            <span class="tw-font-semibold">
              {{ vendor.name }}
            </span>

            <button @click="singleReview = false" class="tw-cursor-pointer">
              <x-icon />
            </button>

          </div>

          <div class="tw-flex tw-justify-between">
            <div class="tw-flex tw-items-center tw-mb-4">
              <img :src="review.author.avatar" class="tw-h-9 tw-w-9 tw-mr-3 tw-rounded-full" />
              <span>
                {{ review.author.name }}

                <div class="tw-flex tw-items-center">
                  <v-rating :value="review.rating" color="red" background-color="red" class="tw-flex" dense
                    emptyIcon='mdi-heart-outline' fullIcon='mdi-heart' halfIcon='mdi-heart-half-full' half-increments
                    readonly size="12"></v-rating>
                  <span style="font-size: 10px" class="tw-text-red tw-font-semibold tw-ml-1">{{ review.rating }}</span>
                </div>
              </span>
            </div>

            <span class="tw-text-gray-n3 tw-text-xs">
              {{review.created_at}}
            </span>
          </div>


          <span class="tw-mt-4">
            {{review.body}}
          </span>
        </div>

      </v-dialog>


      <!-- Modal for reviews -->
      <v-dialog
          v-model="dialogReviews"
          width="550px"
      >
        <div class="tw-bg-white tw-p-10">
          <span class="tw-bg-white tw-flex tw-justify-between tw-items-center tw-mb-4">
            <span class="tw-text-xl tw-font-semibold tw-flex tw-gap-3.5 tw-items-center">
              <star-icon/>
              Reviews for {{ vendor.name }}
            </span>
            <button @click="dialogReviews = false" class="tw-cursor-pointer">
              <x-icon/>
            </button>
          </span>
          <Reviews :vendor="vendor"/>
        </div>
      </v-dialog>

      <!-- Modal for request quote -->
      <v-dialog v-model="dialogQuote" max-width="600" persistent v-if="vendor">
        <div>
            <QuoteForm :vendor=vendor :chat=chat @close="clearQuote"></QuoteForm>
        </div>
      </v-dialog>

      <!-- Modal for messages -->
      <v-dialog v-model="messageDialog" max-width="600" persistent>
        <div>
          <MsgForm :vendor=vendor @close="clearMessage"></MsgForm>
        </div>
      </v-dialog>

      <!-- Video -->
      <v-dialog content-class="elevation-0" v-model="showVideoDialog" @click:outside="stopPlayVideo" :max-width="560">
        <div class="tw-max-w-full tw-max-h-full tw-flex tw-flex-col">
          <v-btn icon dark @click="stopPlayVideo" class="tw-self-end tw-inline-block">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <Video :youtube=null :vimeo=null :video=videoToShow :type=videoType :stopPlay=stopPlay :autoplay=true />
        </div>
      </v-dialog>


    </div>
  </div>


</template>


<script>


import VendorShowcaseProduct from '../components/VendorShowcaseProduct.vue'
//import Calendar from '../components/CalendarByVendor.vue'
import QuoteForm from '../components/QuoteForm.vue';
import MsgForm from '../components/MsgForm.vue';
import Reviews from '../components/Reviews.vue';
//import ShowcaseworkFilter from '../components/DataFilter.vue';
//import Messages from '../components/Message.vue';

import { mapGetters } from 'vuex'
import Gallery from "@/components/Gallery";
import Video from "@/components/Video.vue";
import { InstagramIcon, FacebookIcon, PenToolIcon, XIcon , StarIcon, MapPinIcon} from "vue-feather-icons";
import Button from "@/components/Button.vue";
import { MessageSquareIcon } from 'vue-feather-icons'
import Category from "@/components/CategoryButton.vue"

export default {
  //middleware: ['guest'],
  components: {
    VendorShowcaseProduct,
    //Calendar,
    QuoteForm,
    Reviews,
    MsgForm,
    //ShowcaseworkFilter,
    //Messages
    Gallery,
    //ArrowUpRightIcon,
    InstagramIcon,
    FacebookIcon,
    PenToolIcon,
    XIcon,
    StarIcon,
    MapPinIcon,
    Video,
    Button,
    MessageSquareIcon,
    Category,
  },
  metaInfo() {
    return {
      title: this.vendor.showcasework ? this.vendor.showcasework.title:null,
    }
  },
  data() {
    return {
      review: {
        author: {}
      },
      productTab: 'offers',
      messageDialog: false,
      active_tab: null,
      addonNr: 0,
      imgItem: 0,
      orderStatus: false,
      orderError: false,
      ordermsg: '',
      vendor: {
        categories: [],
        showcasework: {
          vimeo: null,
          youtube: null
        },
        settings:null
      },
      slug: {},
      products: {},
      quotes: {},
      showcasework: {},
      settings: {},
      images: [],
      singleReview: false,
      dialogReviews: false,
      dialogQuote: false,
      showVideoDialog: false,
      videoToShow: null,
      videoType: null,
      stopPlay: false,
      chat: null,
      loading: false,
      drawerR: true,
      drawerL: true,
      items: [
        { title: 'Contact us', icon: 'mdi-email' },
        { title: 'Instagram', icon: 'mdi-instagram' },
        { title: 'Facebook', icon: 'mdi-facebook' },
      ],
      mini: true,
      miniR: false,
      showcalendar: false,
      selectDates: [],
      datesSeleted: false,
      dates: [],
      gallery: false,
      errored: false,
      errorMsg: '',
      ploading: false,
      qloading: false,
      ratings: [],
      filterDates: null,
      uniqueParents: {},
      showCountries: false,
      showCategories: false,
    }
  },
  mounted() {

    const params = this.filterParams ?? {};
    params["filter[category_in]"] = null;
    this.$store.dispatch('filter/setFilterParams', params);
    this.getVendor();
    /*
    if (localStorage.getItem("filterDates")){
      this.filterDates = JSON.parse(localStorage.getItem("filterDates"));
      this.dates = this.filterDates;
    }
    */

    if (this.preselectedDates.length > 0) {
      //console.log(this.preselectedDates);
      this.dates = this.preselectedDates.split(',');
    }
  },
  computed: {
    displayedCountries() {
      return this.vendor.countries.slice(0, 3);
    },
    categories() {
      let vendorCategories = []
      if(this.vendor.categories){
        this.vendor.categories.forEach(category => {
          if (category.parents.length > 0) {
            let parentId = category.parents[0].id;
            if (!this.uniqueParents[parentId]) {
              this.uniqueParents[parentId] = true;
              vendorCategories.push({
                id: category.parents[0].id,
                name: category.parents[0].name,
                mdi: category.parents[0].mdi
              });
            }
          }
        });
      }

      return vendorCategories;
    },
    displayedCategories() {
      const limit = this.categories.length > 3 ? 2 : 3;
      return this.categories.slice(0, limit);
    },
    Dates() {
      return this.dates.map(day => new Date(day));
    },
    attributes() {
      return this.Dates.map(date => ({
        highlight: {
          class: 'tw-bg-red',
        },
        dates: date,
      }));
    },
    ...mapGetters({
      user: 'auth/user',
      authenticated: 'auth/authenticated',
      filterParams: 'filter/getFilterParams',
      //getOrderStatus: 'cart/getOrderStatus'
    }),
    fontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '3em';
        default:
          return '5em';
      }
    },
    miniSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true;
        case 'sm':
          return true;
        case 'md':
          return true;
        case 'lg':
          return false;
        case 'xl':
          return false;
        default:
          return false;
      }
    },
    maxSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return false;
        case 'sm':
          return false;
        case 'md':
          return false;
        case 'lg':
          return true;
        case 'xl':
          return true;
        default:
          return true;
      }
    },
    preselectedDates() {
      return this.filterParams && this.filterParams["filter[availability_dates_in]"] ? this.filterParams["filter[availability_dates_in]"] : [];
    },
  },
  watch: {
    vendor: function () {
      // this.showcasework = this.vendor.showcasework;
      // this.settings = this.vendor.settings;
    },
    /*
    getOrderStatus: function () {
      if (this.getOrderStatus) {
        this.getProducts(false, this.vendor.id);
        let status = false;
        this.$store.dispatch('cart/setOrderStatus', {status});
        let cart = false;
        this.$store.dispatch('cart/setCartWindow', {cart});
      }
    },
    */
    dates: function () {

      if (!this.vendor.id) return;
      const params = this.filterParams ?? {};

      if (!this.dates || this.dates === "" || this.dates.length === 0) {
        params["filter[availability_dates_in]"] = null;
        this.$store.dispatch('filter/setFilterParams', params);
        this.getProducts(true, this.vendor.id);
        return;
      }

      //if(!this.preselectedDates) return;
      //if(JSON.stringify(this.dates) === JSON.stringify(this.preselectedDates.split(','))) return;


      params["filter[availability_dates_in]"] = this.dates.toString();
      this.$store.dispatch('filter/setFilterParams', params);
      //console.log(this.filterParams);
      this.getProducts(true, this.vendor.id);
    },
  },
  methods: {
    reviewClicked(item) {
      this.review = item;
      this.singleReview = true;
    },
    onDayClick(day) {

      if (this.isDatePast(day.id)) return;

      const idx = this.dates.findIndex(d => d === day.id);
      if (idx >= 0) {
        this.dates.splice(idx, 1);
      } else {
        this.dates.push(day.id);
      }
    },
    isDatePast(date) {
      const today = new Date().setHours(0, 0, 0, 0);
      const past = new Date(date).setHours(0, 0, 0, 0);
      return past < today;
    },
    playVideo(type) {

      //console.log(type);
      this.stopPlay = false;
      if (this.vendor.showcasework && type == 'youtube') {
        this.videoType = type;
        this.videoToShow = this.vendor.showcasework.youtube;
        this.showVideoDialog = true;
      }
      if (this.vendor.showcasework && type == 'vimeo') {
        this.videoType = type;
        this.videoToShow = this.vendor.showcasework.vimeo;
        this.showVideoDialog = true;
      }
    },
    stopPlayVideo() {
      this.stopPlay = true;
      this.showVideoDialog = false;
    },
    goTo(url) {

      if (url.substring(0, 7) !== 'http://' && url.substring(0, 8) !== 'https://') {
        url = 'https://' + url;
      }
      window.open(url, '_blank');
    },
    clearMessage() {
      this.messageDialog = false;
    },
    clearQuote() {
      this.dialogQuote = false;
    },
    quoteRequest(data) {
      this.chat = data.item;
      // this.dialogQuote = true; // prebacen dialog u message direktno.

    },

    onselectedDates(dates) {
      this.selectDates = dates;
    },
    async getVendorIdBySlug(slug) {
      let uri = '/api/company/slug';

      await this.$http.get(uri, {
        params: {
          slug: slug
        }
      })
        .then((response) => {
          //console.log(response.data.data);
          this.slug = response.data.data;
        })
        .catch(error => {
          this.$router.push({ name: 'feed' });
          this.errorMsg = error;
          this.errored = true;
        });

    },
    async getVendor() {
      this.loading = true;

      let id = this.$route.params.id;
      //console.log(id);
      if (isNaN(id)) {
        // it's a string
        //console.log("it's a string");
        await this.getVendorIdBySlug(id);
        //console.log(this.slug);
        id = this.slug.id;
      }

      let uri = '/api/vendors/' + id + "?include=companies,categories";
      this.$http.get(uri)
        .then((response) => {
          this.vendor = response.data.data;
          this.images = response.data.data.showcasework ? response.data.data.showcasework.images : [];
          this.showcalendar = true;
          this.getProducts(false, this.vendor.id);
          this.getQuotes(false, this.vendor.id);
          this.getRatings(this.vendor.id);
        })
        .catch(error => {
          //console.log(error);
          //this.$router.push({name: 'feed'});
          if (error.status == '404') {
            this.$router.push({ name: 'home' });
          }
          this.errorMsg = error;
          this.errored = true;
          //this.loading = false;
        })
        .finally(() =>
          //this.vendor.showcasework ? this.images = this.vendor.showcasework.images:null,
          this.loading = false
        );
    },
    async getProducts(e, id) {

      if (this.dates) {
        e = true;
      }
      // this.$route.params.id
      this.ploading = true;
      const param = e == true ? this.filterParams : null;
      //const param = this.filterParams ? this.filterParams:null;

      console.log("Loading products...");
      let uri = '/api/products/vendor/' + id;
      await this.$http.get(uri, { params: param })
        .then((response) => {
          this.products = response.data.data
        })
        .catch(error => {
          this.errorMsg = error;
          this.errored = true;
        }).finally(() => this.ploading = false);
    },
    getQuotes(e, id) {

      this.qloading = true;
      const param = e == true ? this.filterParams : null;

      let uri = '/api/quotes/vendor/' + id;
      this.$http.get(uri, { params: param })
        .then((response) => {
          this.quotes = response.data.data
        })
        .catch(error => {
          this.errorMsg = error;
          this.errored = true;
        }).finally(() => this.qloading = false);
    },
    getRatings(id) {
      let uri = '/api/ratings/' + id;
      this.$http.get(uri)
        .then((response) => {
          this.ratings = response.data.data
        })
        .catch(error => {
          this.errorMsg = error;
          this.errored = true;
        });
    },
  }
}
</script>
<style scoped>
.instagram {
  background: #d6249f;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  box-shadow: 0px 0px 0px rgba(0, 0, 0, .25);
}

.facebook {
  background: #3B5998;
  color: white;
}
</style>
